import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const OfferingsPage = () => (
  <Layout>
    <Seo
      title="Paarberatung"
      description="Ich begleite sie als Paar bei Krisen und Konflikten."
      keywords={['Paarberatung', 'Paar', 'Trennung', 'Scheidung', 'Beratung']}
    />
    <article>
      <div className="fl w-100 w-50-ns pr2">
        <StaticImage
          src={'../images/paar.jpg'}
          alt="Paar"
          imgClassName="mb2 mr2"
        />
      </div>
      <div className="fl w-100 w-50-ns">
        <h1>Paarberatung</h1>
        <p>
          Im Leben vieler Menschen steht eine Partnerbeziehung oder das
          familiäre Zusammenleben im Mittelpunkt.
        </p>
        <p>
          Konflikte und Krisen bleiben allerdings nicht aus. Beratung kann
          helfen, die Verhärtung von Konflikten zu vermeiden, eine anstehende
          Entscheidung begründet zu treffen und durch aktive Auseinandersetzung
          aus verfahrenen Konstellationen herauszufinden.
        </p>
        <p>Hier einige Beispielanlässe: </p>
        <ul>
          <li>
            Beraterische Begleitung beim Übergang von der Paarbeziehung zur
            dreiköpfigen Familie
          </li>
          <li>
            Ein Paar ist bereit, für das Weiterbestehen einer Beziehung zu
            kämpfen
          </li>
          <li>
            Die Häufigkeit und der Eskalationsgrad von aufreibenden
            Streitsituationen soll eingedämmt werden
          </li>
          <li>
            Beratung soll eine Trennung abfedern, damit sich die wechselseitigen
            Verletzungen in Grenzen halten
          </li>
          <li>
            Es besteht das gemeinsame Bestreben, wieder mehr Lebendigkeit und
            Gemeinsamkeit in der Paarbeziehung zu erleben
          </li>
        </ul>
      </div>
    </article>
  </Layout>
)

export default OfferingsPage
